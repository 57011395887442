import type { $Fetch } from 'ofetch'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import type { User, SignIn } from './types'

import CdpService from '@/services/cdpService'
import type UniversalAuthService from '@/services/universalAuthService'

export const useUserStore = defineStore('user', () => {
  const user = ref<User|null>(null)
  const employeeUser = ref<User|null>(null)
  const authService = ref<InstanceType<typeof UniversalAuthService> | null>(null)

  const isAuthenticated = computed(() => !!user.value?.id)
  const isEmployeeAuthenticated = computed(() => !!employeeUser.value?.id)

  const getUser = async (id: string) => {
    const logger = useLogger()
    const { $config } = useNuxtApp()

    try {
      const cdpService = new CdpService({
        apiFetch: globalThis.$fetch as $Fetch,
        cdpUrl: $config.public.cdpApiUrl as string,
        xApiKey: $config.public.cdpApiKey as string,
        xApplicationId: $config.public.environment
      })
      user.value = await cdpService.getCustomer(id)
    } catch (error) {
      logger.error({
        message: 'getUser',
        stack: error
      })
    }
  }

  const importUniversalAuthService = async (role = 'customer') => {
    const { $config } = useNuxtApp()

    const { default: UniversalAuthService } = await import('@/services/universalAuthService')
    const authId = role === 'customer' ? $config.public.laHausAuthId : $config.public.laHausEmployeeAuthId
    authService.value = new UniversalAuthService(
      authId,
      $config.public.environment
    )
  }

  const setUser = (newUser: User) => {
    user.value = newUser
  }

  const setEmployeeUser = (newUser: User) => {
    employeeUser.value = newUser
  }

  const signIn: SignIn = async ({ redirectUrl, method, role }) => {
    if (method === undefined) method = 'phone'
    if (role === undefined) role = 'customer'
    if (!authService.value) await importUniversalAuthService(role)
    await authService.value!.signIn({ redirectUrl, method })
  }

  const signOut = async () => {
    if (!authService.value) await importUniversalAuthService()

    await authService.value!.signOut()
    const authToken = useCookie<string|null>('lh_auth_token')
    authToken.value = null
    user.value = null
  }

  const userLabel = computed(() => {
    if (!user.value) return ''

    const { name, phone } = user.value
    return name.includes('sin identificar') ? phone : name
  })

  return {
    getUser,
    isAuthenticated,
    isEmployeeAuthenticated,
    setUser,
    signIn,
    signOut,
    user,
    employeeUser,
    setEmployeeUser,
    userLabel,
    importUniversalAuthService
  }
})
