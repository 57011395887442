import type { $Fetch } from 'ofetch'

import AuthService from '@/services/authService'
import { useUserStore } from '@/stores/userStore'

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.client) return

  const nuxtApp = useNuxtApp()
  const { $config } = nuxtApp

  const authToken = useCookie<string|null>('lh_auth_token')
  const authTokenStatus = useCookie<string|null>('lh_auth_token_status')
  if (to.query.token) {
    authToken.value = to.query.token.toString()
  }

  const navigateWithoutToken = () => {
    if (to.query.token) {
      useCookie('lh_auth_identify', { default: () => 'required' })
      delete to.query.token
      return navigateTo(to)
    }
  }

  if (authToken.value) {
    try {
      authTokenStatus.value = null
      const authService = new AuthService({
        apiFetch: globalThis.$fetch as $Fetch,
        authUrl: $config.fintechSsoApiUrl as string,
        xApplicationId: $config.public.environment
      })

      const tokenContent = await authService.validateToken(authToken.value)
      const cdpId = tokenContent.sub
      const role = tokenContent.claims?.role
      const userStore = useUserStore()

      if (role === 'lahaus') {
        userStore.setEmployeeUser({
          id: cdpId,
          name: cdpId,
          email: cdpId,
          phone: cdpId
        })
      } else {
        await userStore.getUser(cdpId)
      }

      return navigateWithoutToken()
    } catch (error: any) {
      if (error.code === 'expired_token') {
        nuxtApp.$authTokenStatus = error.code
        authTokenStatus.value = error.code

        return navigateWithoutToken()
      }

      authToken.value = null
      const logger = useLogger()
      logger.error({
        message: error.message,
        stack: error
      })

      return navigateWithoutToken()
    }
  }
})
