import type { Headers, Params, ListingDataServiceMethods } from './types'

export default class ListingDataService implements ListingDataServiceMethods {
  headers: Headers
  listingUrl: Params['listingUrl']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.listingUrl = params.listingUrl
    this.headers = {
      'X-Application-Id': params.xApplicationId,
      'x-api-key': params.xApiKey,
      'Content-Type': 'application/json'
    }
  }

  async getListing (slug: string) {
    try {
      const results = await this.apiFetch(`${this.listingUrl}/listing/residential-complex/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          show_all: true,
          pagination: {
            limit: 1,
            offset: 0
          },
          filters: {
            and: [
              {
                'slugs.keyword': slug
              }
            ]
          },
          fields: [
            'address',
            'attributes',
            'code',
            'companies',
            'description',
            'location',
            'name',
            'slugs',
            'seo.path',
            'seo.url',
            'ims',
            'cms.categories_info'
          ],
          sort: [
            {
              'name.keyword': {
                order: 'asc'
              }
            }
          ]
        }
      })

      return results
    } catch (error: any) {
      throw error.data
    }
  }

  async getUnits (slug: string) {
    try {
      const result = await this.apiFetch(`${this.listingUrl}/listing/units/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          pagination: {
            limit: 990,
            offset: 0
          },
          filters: {
            and: [
              { 'residential_complex_info.slugs.keyword': slug },
              { status: 'available' }
            ]
          },
          aggregations: {
            areas: 'attributes.area_total'
          },
          fields: [
            'name',
            'code',
            'attributes.price',
            'attributes.area_total',
            'attributes.bedroom',
            'attributes.level',
            'typology_info.attributes.bedroom',
            'attributes.product_subtype'
          ]
        }
      })

      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async getTypologies (slug: string) {
    try {
      const results = await this.apiFetch(`${this.listingUrl}/listing/residential-complex/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          pagination: {
            limit: 1,
            offset: 0
          },
          filters: {
            and: [
              {
                'slugs.keyword': slug
              }
            ]
          },
          fields: [
            'buildings.typologies',
            'sections.typologies'
          ],
          sort: [
            {
              'name.keyword': {
                order: 'asc'
              }
            }
          ]
        }
      })

      return results
    } catch (error: any) {
      throw error.data
    }
  }

  async getTypologiesByLowsPrice (typologiesCodes: Array<string>) {
    try {
      const results = await this.apiFetch(`${this.listingUrl}/listing/units/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          pagination: {
            limit: 1,
            offset: 0
          },
          filters: {
            and: [
              {
                status: 'available'
              },
              {
                terms: {
                  'typology_info.code': typologiesCodes
                }
              }
            ]

          },
          sort: [
            {
              'attributes.price': {
                order: 'asc'
              }
            }
          ],
          aggregations: {
            typologies: {
              terms: {
                field: 'typology_info.code',
                size: 40
              },
              aggregations: {
                min_prices: {
                  min: {
                    field: 'attributes.price'
                  }
                },
                max_prices: {
                  max: {
                    field: 'attributes.price'
                  }
                }
              }
            }
          },
          fields: [
            'attributes.price',
            'typology_info.code'
          ]
        }
      })

      return results
    } catch (error: any) {
      throw new Error(error)
    }
  }

  async getCheapAndExpensiveUnitsByTypology (typologyCode: string) {
    try {
      const result = await this.apiFetch(`${this.listingUrl}/listing/units/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          pagination: { limit: 1 },
          sort: [
            {
              'attributes.price': { order: 'asc' }
            }
          ],
          filters: {
            and: [
              {
                status: 'available'
              },
              {
                'typology_info.code': typologyCode
              }
            ]
          },
          aggregations: {
            min: {
              terms: {
                field: 'code',
                size: 1,
                order: { price: 'asc' }
              },
              aggregations: {
                price: {
                  max: { field: 'attributes.price' }
                }
              }
            },
            max: {
              terms: {
                field: 'code',
                size: 1,
                order: { price: 'desc' }
              },
              aggregations: {
                price: {
                  min: { field: 'attributes.price' }
                }
              }
            }
          }
        }
      })

      const cheapUnit = result.rows[0]
      const hiLow = {
        min: result.aggregations.min.buckets[0],
        max: result.aggregations.max.buckets[0]
      }

      return {
        cheapUnit,
        hiLow
      }
    } catch (error: any) {
      throw error.data
    }
  }

  async getLevels (projectCode: string, typologyCode: string) {
    try {
      const results = await this.apiFetch(`${this.listingUrl}/listing/units/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          filters: {
            and: [
              { 'residential_complex_info.code': projectCode },
              { status: 'available' },
              { 'typology_info.code': typologyCode }
            ]
          },
          aggregations: {
            areas: 'attributes.level'
          }
        }
      })

      return results
    } catch (error: any) {
      throw error.data
    }
  }

  async getUnitsByLevel (projectCode: string, level: string, typologyCode: string) {
    try {
      const results = await this.apiFetch(`${this.listingUrl}/listing/units/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          pagination: {
            limit: 999,
            offset: 0
          },
          filters: {
            and: [
              { 'residential_complex_info.code': projectCode },
              { 'attributes.level': level },
              { status: 'available' },
              { 'typology_info.code': typologyCode }
            ]
          }
        }
      })

      return results
    } catch (error: any) {
      throw error.data
    }
  }

  async getUnitByCode (unitCode: string, showAll: boolean = false) {
    try {
      const results = await this.apiFetch(`${this.listingUrl}/listing/units/filter`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          pagination: {
            limit: 1,
            offset: 0
          },
          filters: {
            and: [
              { code: unitCode }
            ]
          },
          show_all: showAll
        }
      })
      return results
    } catch (error: any) {
      throw error.data
    }
  }
}
