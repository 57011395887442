import type { $Fetch } from 'ofetch'
import StrapiService from '@/services/strapiService'

export default defineNuxtPlugin(({ $config }) => {
  const strapiService = new StrapiService({
    apiFetch: globalThis.$fetch as $Fetch,
    apiUrl: $config.public.cmsStrapiDomain
  })

  const getProjectRecomGroups = async (clusterSlug?: string) => {
    const groups = await strapiService.getProjectRecomGroups(clusterSlug)

    if (!groups?.data) return []

    const chips = groups.data.map((item: Record<string, any>, index: number) => {
      const chip = item.attributes
      return {
        id: item.id || index.toString(),
        title: chip.title,
        message: chip.sam_prompt,
        type: 'INITIAL',
        metadata: {
          listingIds: chip?.listing_ids?.split(',')
        }
      }
    })

    return chips
  }

  return {
    provide: {
      getProjectRecomGroups
    }
  }
})
