import type { Headers, Params, SobreplanosDataServiceMethods } from './types'

export default class SobreplanosDataService implements SobreplanosDataServiceMethods {
  headers: Headers
  urlBase: Params['urlBase']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.urlBase = params.urlBase
    this.headers = {
      'X-Application-Id': params.xApplicationId,
      'Content-Type': 'application/json'
    }
  }

  async getListing (slug: string, refresh: boolean, showAll: boolean = false) {
    try {
      const result = await this.apiFetch(`${this.urlBase}/api/v1/real_estate_search`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          filters: {
            slug
          },
          type: 'listing-detail-investors',
          show_all: showAll,
          refresh
        }
      })

      return result
    } catch (error) {
      throw error.data
    }
  }

  async getListings ({ filters = {}, sort = {}, pagination = {}, refresh }) {
    try {
      const result = await this.apiFetch(`${this.urlBase}/api/v1/real_estate_search`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          filters,
          sort,
          pagination,
          type: 'listing-investors-paginated',
          refresh
        }
      })

      return result
    } catch (error) {
      throw error.data
    }
  }

  async getSeoMetadata (path) {
    try {
      const result = await this.apiFetch(`${this.urlBase}/api/v1/seo_metadata`, {
        method: 'POST',
        headers: {
          ...this.headers
        },
        body: {
          path
        }
      })

      return result
    } catch (error) {
      throw error.data
    }
  }
}
