import type { Headers, Params } from './types'

const CdpService = class {
  headers: Headers
  cdpUrl: Params['cdpUrl']
  apiFetch: Params['apiFetch']

  constructor (params: Params) {
    this.apiFetch = params.apiFetch
    this.cdpUrl = params.cdpUrl
    this.headers = {
      'x-application-id': params.xApplicationId,
      'x-api-key': params.xApiKey,
      'Content-Type': 'application/json'
    }
  }

  async getCustomer (id: string) {
    try {
      const endpoint = `${this.cdpUrl}?id=${id}`
      const result = await this.apiFetch(endpoint, {
        method: 'GET',
        headers: {
          ...this.headers
        }
      })

      return result
    } catch (error: any) {
      throw error.data
    }
  }

  async getCustomerByPhone (phone: string) {
    try {
      const endpoint = `${this.cdpUrl}?phone=${phone}`
      const result = await this.apiFetch(endpoint, {
        method: 'GET',
        headers: {
          ...this.headers
        },
        retryStatusCodes: [500, 404],
        retry: 5,
        retryDelay: 1000
      })

      return result
    } catch (error: any) {
      throw error.data
    }
  }
}

export default CdpService
