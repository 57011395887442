import { default as _91_91turnScheduledId_93_93mQoMTSLadAMeta } from "/app/pages/brochure/[slug]/[[turnScheduledId]].vue?macro=true";
import { default as indexdPFcp78SB4Meta } from "/app/pages/brochure/[slug]/simulator/index.vue?macro=true";
import { default as indexaCJDAu3fu3Meta } from "/app/pages/brochure/recommendations/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91typologyCode_93YweQXdCdmSMeta } from "/app/pages/inventory/[slug]/[typologyCode].vue?macro=true";
import { default as indexoR1u6tOhl5Meta } from "/app/pages/inventory/[slug]/index.vue?macro=true";
import { default as indexDlAzw9SW2LMeta } from "/app/pages/investment-content/[slug]/index.vue?macro=true";
import { default as indexh0z3yeeTl3Meta } from "/app/pages/investment-content/index/index.vue?macro=true";
import { default as investment_45simulatorzuRyjCLtJVMeta } from "/app/pages/investment-simulator.vue?macro=true";
import { default as onboardingY3u2kqL19PMeta } from "/app/pages/onboarding.vue?macro=true";
import { default as indexRIsCNJw84UMeta } from "/app/pages/pd/[city]/[slug]/index.vue?macro=true";
import { default as indexfWJtuVO6QpMeta } from "/app/pages/public-brochure/[slug]/[cdpUserId]/index.vue?macro=true";
import { default as _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta } from "/app/pages/quotation-plan/[slug]/[unitCode]-[quotationPlanId].vue?macro=true";
import { default as indexkbX2hihDwsMeta } from "/app/pages/quotation-plan/[slug]/index.vue?macro=true";
import { default as indexLHC1jTyOz3Meta } from "/app/pages/search/[property]/[[city]]/[[zone]]/[[neighborhood]]/index.vue?macro=true";
import { default as indexA8Bs7q3C4GMeta } from "/app/pages/simulator/[slug]/[quotationId]/[cdpId]/index.vue?macro=true";
export default [
  {
    name: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.name ?? "brochure-slug-turnScheduledId___mx",
    path: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.path ?? "/brochure/:slug()/:turnScheduledId?",
    meta: _91_91turnScheduledId_93_93mQoMTSLadAMeta || {},
    alias: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.alias || [],
    redirect: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.redirect,
    component: () => import("/app/pages/brochure/[slug]/[[turnScheduledId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.name ?? "brochure-slug-turnScheduledId___co",
    path: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.path ?? "/brochure/:slug()/:turnScheduledId?",
    meta: _91_91turnScheduledId_93_93mQoMTSLadAMeta || {},
    alias: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.alias || [],
    redirect: _91_91turnScheduledId_93_93mQoMTSLadAMeta?.redirect,
    component: () => import("/app/pages/brochure/[slug]/[[turnScheduledId]].vue").then(m => m.default || m)
  },
  {
    name: indexdPFcp78SB4Meta?.name ?? "brochure-slug-simulator___mx",
    path: indexdPFcp78SB4Meta?.path ?? "/brochure/:slug()/simulator",
    meta: indexdPFcp78SB4Meta || {},
    alias: indexdPFcp78SB4Meta?.alias || [],
    redirect: indexdPFcp78SB4Meta?.redirect,
    component: () => import("/app/pages/brochure/[slug]/simulator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPFcp78SB4Meta?.name ?? "brochure-slug-simulator___co",
    path: indexdPFcp78SB4Meta?.path ?? "/brochure/:slug()/simulator",
    meta: indexdPFcp78SB4Meta || {},
    alias: indexdPFcp78SB4Meta?.alias || [],
    redirect: indexdPFcp78SB4Meta?.redirect,
    component: () => import("/app/pages/brochure/[slug]/simulator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaCJDAu3fu3Meta?.name ?? "brochure-recommendations___mx",
    path: indexaCJDAu3fu3Meta?.path ?? "/brochure/recommendations",
    meta: indexaCJDAu3fu3Meta || {},
    alias: indexaCJDAu3fu3Meta?.alias || [],
    redirect: indexaCJDAu3fu3Meta?.redirect,
    component: () => import("/app/pages/brochure/recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: indexaCJDAu3fu3Meta?.name ?? "brochure-recommendations___co",
    path: indexaCJDAu3fu3Meta?.path ?? "/brochure/recommendations",
    meta: indexaCJDAu3fu3Meta || {},
    alias: indexaCJDAu3fu3Meta?.alias || [],
    redirect: indexaCJDAu3fu3Meta?.redirect,
    component: () => import("/app/pages/brochure/recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___mx",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___co",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91typologyCode_93YweQXdCdmSMeta?.name ?? "inventory-slug-typologyCode___mx",
    path: _91typologyCode_93YweQXdCdmSMeta?.path ?? "/inventory/:slug()/:typologyCode()",
    meta: _91typologyCode_93YweQXdCdmSMeta || {},
    alias: _91typologyCode_93YweQXdCdmSMeta?.alias || [],
    redirect: _91typologyCode_93YweQXdCdmSMeta?.redirect,
    component: () => import("/app/pages/inventory/[slug]/[typologyCode].vue").then(m => m.default || m)
  },
  {
    name: _91typologyCode_93YweQXdCdmSMeta?.name ?? "inventory-slug-typologyCode___co",
    path: _91typologyCode_93YweQXdCdmSMeta?.path ?? "/inventory/:slug()/:typologyCode()",
    meta: _91typologyCode_93YweQXdCdmSMeta || {},
    alias: _91typologyCode_93YweQXdCdmSMeta?.alias || [],
    redirect: _91typologyCode_93YweQXdCdmSMeta?.redirect,
    component: () => import("/app/pages/inventory/[slug]/[typologyCode].vue").then(m => m.default || m)
  },
  {
    name: indexoR1u6tOhl5Meta?.name ?? "inventory-slug___mx",
    path: indexoR1u6tOhl5Meta?.path ?? "/inventory/:slug()",
    meta: indexoR1u6tOhl5Meta || {},
    alias: indexoR1u6tOhl5Meta?.alias || [],
    redirect: indexoR1u6tOhl5Meta?.redirect,
    component: () => import("/app/pages/inventory/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoR1u6tOhl5Meta?.name ?? "inventory-slug___co",
    path: indexoR1u6tOhl5Meta?.path ?? "/inventory/:slug()",
    meta: indexoR1u6tOhl5Meta || {},
    alias: indexoR1u6tOhl5Meta?.alias || [],
    redirect: indexoR1u6tOhl5Meta?.redirect,
    component: () => import("/app/pages/inventory/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDlAzw9SW2LMeta?.name ?? "investment-content-slug___mx",
    path: indexDlAzw9SW2LMeta?.path ?? "/contenido-de-inversion/:slug()",
    meta: indexDlAzw9SW2LMeta || {},
    alias: indexDlAzw9SW2LMeta?.alias || [],
    redirect: indexDlAzw9SW2LMeta?.redirect,
    component: () => import("/app/pages/investment-content/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDlAzw9SW2LMeta?.name ?? "investment-content-slug___co",
    path: indexDlAzw9SW2LMeta?.path ?? "/contenido-de-inversion/:slug()",
    meta: indexDlAzw9SW2LMeta || {},
    alias: indexDlAzw9SW2LMeta?.alias || [],
    redirect: indexDlAzw9SW2LMeta?.redirect,
    component: () => import("/app/pages/investment-content/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexh0z3yeeTl3Meta?.name ?? "investment-content-index___mx",
    path: indexh0z3yeeTl3Meta?.path ?? "/contenido-de-inversion",
    meta: indexh0z3yeeTl3Meta || {},
    alias: indexh0z3yeeTl3Meta?.alias || [],
    redirect: indexh0z3yeeTl3Meta?.redirect,
    component: () => import("/app/pages/investment-content/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexh0z3yeeTl3Meta?.name ?? "investment-content-index___co",
    path: indexh0z3yeeTl3Meta?.path ?? "/contenido-de-inversion",
    meta: indexh0z3yeeTl3Meta || {},
    alias: indexh0z3yeeTl3Meta?.alias || [],
    redirect: indexh0z3yeeTl3Meta?.redirect,
    component: () => import("/app/pages/investment-content/index/index.vue").then(m => m.default || m)
  },
  {
    name: investment_45simulatorzuRyjCLtJVMeta?.name ?? "investment-simulator___mx",
    path: investment_45simulatorzuRyjCLtJVMeta?.path ?? "/simulador-de-inversion",
    meta: investment_45simulatorzuRyjCLtJVMeta || {},
    alias: investment_45simulatorzuRyjCLtJVMeta?.alias || [],
    redirect: investment_45simulatorzuRyjCLtJVMeta?.redirect,
    component: () => import("/app/pages/investment-simulator.vue").then(m => m.default || m)
  },
  {
    name: investment_45simulatorzuRyjCLtJVMeta?.name ?? "investment-simulator___co",
    path: investment_45simulatorzuRyjCLtJVMeta?.path ?? "/simulador-de-inversion",
    meta: investment_45simulatorzuRyjCLtJVMeta || {},
    alias: investment_45simulatorzuRyjCLtJVMeta?.alias || [],
    redirect: investment_45simulatorzuRyjCLtJVMeta?.redirect,
    component: () => import("/app/pages/investment-simulator.vue").then(m => m.default || m)
  },
  {
    name: onboardingY3u2kqL19PMeta?.name ?? "onboarding___mx",
    path: onboardingY3u2kqL19PMeta?.path ?? "/onboarding",
    meta: onboardingY3u2kqL19PMeta || {},
    alias: onboardingY3u2kqL19PMeta?.alias || [],
    redirect: onboardingY3u2kqL19PMeta?.redirect,
    component: () => import("/app/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: onboardingY3u2kqL19PMeta?.name ?? "onboarding___co",
    path: onboardingY3u2kqL19PMeta?.path ?? "/onboarding",
    meta: onboardingY3u2kqL19PMeta || {},
    alias: onboardingY3u2kqL19PMeta?.alias || [],
    redirect: onboardingY3u2kqL19PMeta?.redirect,
    component: () => import("/app/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: indexRIsCNJw84UMeta?.name ?? "pd-city-slug___mx",
    path: indexRIsCNJw84UMeta?.path ?? "/pd/:city()/:slug()",
    meta: indexRIsCNJw84UMeta || {},
    alias: indexRIsCNJw84UMeta?.alias || [],
    redirect: indexRIsCNJw84UMeta?.redirect,
    component: () => import("/app/pages/pd/[city]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRIsCNJw84UMeta?.name ?? "pd-city-slug___co",
    path: indexRIsCNJw84UMeta?.path ?? "/pd/:city()/:slug()",
    meta: indexRIsCNJw84UMeta || {},
    alias: indexRIsCNJw84UMeta?.alias || [],
    redirect: indexRIsCNJw84UMeta?.redirect,
    component: () => import("/app/pages/pd/[city]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfWJtuVO6QpMeta?.name ?? "public-brochure-slug-cdpUserId___mx",
    path: indexfWJtuVO6QpMeta?.path ?? "/public-brochure/:slug()/:cdpUserId()",
    meta: indexfWJtuVO6QpMeta || {},
    alias: indexfWJtuVO6QpMeta?.alias || [],
    redirect: indexfWJtuVO6QpMeta?.redirect,
    component: () => import("/app/pages/public-brochure/[slug]/[cdpUserId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfWJtuVO6QpMeta?.name ?? "public-brochure-slug-cdpUserId___co",
    path: indexfWJtuVO6QpMeta?.path ?? "/public-brochure/:slug()/:cdpUserId()",
    meta: indexfWJtuVO6QpMeta || {},
    alias: indexfWJtuVO6QpMeta?.alias || [],
    redirect: indexfWJtuVO6QpMeta?.redirect,
    component: () => import("/app/pages/public-brochure/[slug]/[cdpUserId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.name ?? "quotation-plan-slug-unitCode-quotationPlanId___mx",
    path: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.path ?? "/planes-de-pago/:slug()/:unitCode()-:quotationPlanId()",
    meta: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta || {},
    alias: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.alias || [],
    redirect: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.redirect,
    component: () => import("/app/pages/quotation-plan/[slug]/[unitCode]-[quotationPlanId].vue").then(m => m.default || m)
  },
  {
    name: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.name ?? "quotation-plan-slug-unitCode-quotationPlanId___co",
    path: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.path ?? "/planes-de-pago/:slug()/:unitCode()-:quotationPlanId()",
    meta: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta || {},
    alias: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.alias || [],
    redirect: _91unitCode_93_45_91quotationPlanId_937nbhcHowo8Meta?.redirect,
    component: () => import("/app/pages/quotation-plan/[slug]/[unitCode]-[quotationPlanId].vue").then(m => m.default || m)
  },
  {
    name: indexkbX2hihDwsMeta?.name ?? "quotation-plan-slug___mx",
    path: indexkbX2hihDwsMeta?.path ?? "/planes-de-pago/:slug()",
    meta: indexkbX2hihDwsMeta || {},
    alias: indexkbX2hihDwsMeta?.alias || [],
    redirect: indexkbX2hihDwsMeta?.redirect,
    component: () => import("/app/pages/quotation-plan/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkbX2hihDwsMeta?.name ?? "quotation-plan-slug___co",
    path: indexkbX2hihDwsMeta?.path ?? "/planes-de-pago/:slug()",
    meta: indexkbX2hihDwsMeta || {},
    alias: indexkbX2hihDwsMeta?.alias || [],
    redirect: indexkbX2hihDwsMeta?.redirect,
    component: () => import("/app/pages/quotation-plan/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLHC1jTyOz3Meta?.name ?? "search-property-city-zone-neighborhood___mx",
    path: indexLHC1jTyOz3Meta?.path ?? "/venta/:property()/:city?/:zone?/:neighborhood?",
    meta: indexLHC1jTyOz3Meta || {},
    alias: indexLHC1jTyOz3Meta?.alias || [],
    redirect: indexLHC1jTyOz3Meta?.redirect,
    component: () => import("/app/pages/search/[property]/[[city]]/[[zone]]/[[neighborhood]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLHC1jTyOz3Meta?.name ?? "search-property-city-zone-neighborhood___co",
    path: indexLHC1jTyOz3Meta?.path ?? "/venta/:property()/:city?/:zone?/:neighborhood?",
    meta: indexLHC1jTyOz3Meta || {},
    alias: indexLHC1jTyOz3Meta?.alias || [],
    redirect: indexLHC1jTyOz3Meta?.redirect,
    component: () => import("/app/pages/search/[property]/[[city]]/[[zone]]/[[neighborhood]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8Bs7q3C4GMeta?.name ?? "simulator-slug-quotationId-cdpId___mx",
    path: indexA8Bs7q3C4GMeta?.path ?? "/simulator/:slug()/:quotationId()/:cdpId()",
    meta: indexA8Bs7q3C4GMeta || {},
    alias: indexA8Bs7q3C4GMeta?.alias || [],
    redirect: indexA8Bs7q3C4GMeta?.redirect,
    component: () => import("/app/pages/simulator/[slug]/[quotationId]/[cdpId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexA8Bs7q3C4GMeta?.name ?? "simulator-slug-quotationId-cdpId___co",
    path: indexA8Bs7q3C4GMeta?.path ?? "/simulator/:slug()/:quotationId()/:cdpId()",
    meta: indexA8Bs7q3C4GMeta || {},
    alias: indexA8Bs7q3C4GMeta?.alias || [],
    redirect: indexA8Bs7q3C4GMeta?.redirect,
    component: () => import("/app/pages/simulator/[slug]/[quotationId]/[cdpId]/index.vue").then(m => m.default || m)
  }
]